import gql from 'graphql-tag'

export const GET_MEMBER_ATTRIBUTE = gql`
  query getMemberAttribute($input: GetMemberAttributeInput!) {
    getMemberAttribute(input: $input) {
      key
      value
      memberId
      id
    }
  }
`

export const SET_MEMBER_ATTRIBUTE = gql`
  mutation upsertMemberAttribute($input: MemberAttributeInput!) {
    upsertMemberAttribute(input: $input) {
      key
      value
      memberId
      id
    }
  }
`

export const GET_PUBLIC_INFO = gql`
  query memberPublicProfile($input: MemberPublicProfileFilter) {
    memberPublicProfile(input: $input) {
      id
      displayName
      profileType
      profileUrl
      value
      averageRating {
        average
      }
    }
  }
`

export const MEMBER_INFO = gql`
  query membersByIds($ids: [Int]) {
    membersByIds(ids: $ids) {
      id
      displayName
      slug
    }
  }
`

export const MEMBER_PAYOUTS = gql`
  query membersByIds($ids: [Int] $filter: PayoutFilter) {
    membersByIds(ids: $ids) {
      id
      payouts(filter: $filter) {
        id
        status
        currency
        amount
        note
        createdOn
      }
    }
  }
`

export const MEMBER_INFO_BY_SLUG = gql`
  query membersBySlugs($input: SlugsInput!) {
    membersBySlugs(input: $input) {
      averageRating {
        average
      }
      displayName
      id
      slug
    }
  }
`

export const FIND_MEMBERS = gql`
  query members ($input: MemberSearchCondition!) {
    members(condition: $input) {
      nodes{
        id
        displayName
        slugs(priority:0) {
          slug
        }
      }
    }
  }
`

export const GET_MEMBER_CONTACT_METHOD = gql`
  query getMemberContactMethod($memberId: Int) {
    getMemberContactMethod(memberId: $memberId) {
      id
    }
  }
`

export const ADD_MEMBER_ASSOCIATION = gql`
  mutation addMemberAssociation($input: MemberAssociationMembershipInput!) {
    addMemberAssociation(input: $input) {
      id
      memberId
    }
  }
`

export const GET_FOLLOWERS = gql`
  query getMemberAssociation($input: MemberAssociationInput!) {
    getMemberAssociation(input: $input) {
      id
      name
      memberships {id, memberId}
      type {name}
    }
  }
`

export const GET_ASSOCIATION = gql`
  query getMemberAssociation($input: MemberAssociationInput!) {
    getMemberAssociation(input: $input) {
      id
      name
      config
      type {name}
    }
  }
`

export const REMOVE_MEMBER_ASSOCIATION = gql`
mutation removeMemberAssociation($input: RemoveAssociationMemberInput!) {
  removeMemberAssociation(input: $input) {
    id
    name
    memberships {memberId}
    type {name}
  }
}
`

export const ADJUST_TAGS = gql`
mutation adjustTags($input: MemberTagAdjustmentInput!) {
  adjustTags(input: $input) {
    id
    tenantId
    tags
  }
}
`

export const GET_TAGS = gql`
query memberTags {
  memberTags {
    id
    name
    protected
  }
}
`
export const FOLLOW_TALENT = gql`
  mutation FollowTalentMutation($followInput: FollowInput!) {
    followTalent(followInput: $followInput) {
      success
      count
      error
    }
  }
`
export const UNFOLLOW_TALENT = gql`
  mutation RemoveTalentFollowMutation($followInput: FollowInput!) {
    unfollowTalent(followInput: $followInput) {
      success
      count
      error
    }
  }
`
export const GET_FOLLOWING_LIST = gql`
  query getFollowList($followInput: FetchFollowingInput!) {
    getFollowList(followInput: $followInput) {
    success
    userList{
      id
      username
      firstName
      lastName
      email
      verified
      profileImg
    }
    first
    skip
    totalRecords
    error
    }
  }
`

export const GET_FOLLOW_TALENT_LIST = gql`
  query FollowTalentOutput($followInput:  FetchFollowingInput! ) {
    getFollowList(followInput: $followInput){
      success
      userList{
        id
        username
        firstName
        lastName
        email
        verified
        profileImg
      }
      first
      skip
      totalRecords
      error
    }
  }
`