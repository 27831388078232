export const TestEmails = [
    "hello+1@meetwithanyone.com",
    "hello+2@meetwithanyone.com",
    "hello+3@meetwithanyone.com",
    "hello+4@meetwithanyone.com",
    "hello+5@meetwithanyone.com",
    "hello+6@meetwithanyone.com",
    "hello+7@meetwithanyone.com",
    "hello+8@meetwithanyone.com",
    "hello+9@meetwithanyone.com",
    "hello+10@meetwithanyone.com",
    "hello+11@meetwithanyone.com",
    "hello+12@meetwithanyone.com",
    "hello+13@meetwithanyone.com",
    "hello+14@meetwithanyone.com",
    "hello+15@meetwithanyone.com",
    "hello+16@meetwithanyone.com",
    "hello+17@meetwithanyone.com",
    "hello+18@meetwithanyone.com",
    "hello+19@meetwithanyone.com",
    "hello+20@meetwithanyone.com",
]

export const switchBoardCategories = [
    "Meet-and-greets", "Online Experiences", "Professional Services", "Lessons"
]