<template>
  <v-card
    tile
    color="grey"
    class="profile-main-image"
    :img="require('@/assets/images/home-screen/background-section-1.webp')"
    alt="background-section"
  >
    <v-container class="pt-10">
      <v-row class="mt-10 pt-10">
        <!-- Left Section -->
        <v-col md="1" sm="12" xs="12" class="mt-4">
          <v-btn
            color="white"
            class="text-none float-left button"
            :ripple="false"
            @click="back()"
          >
            <img src="@/assets/images/icons/left.png" />
            {{ $t('back') }}
          </v-btn>
        </v-col>
        <v-col md="4" class="mt-10 pt-10 ml-md-10 mr-md-10" sm="12" xs="12">
          <v-card rounded="lg" color="transparent" class="video_container">
            <video
              ref="video_profile"
              :controls="talent.profile.introVideo"
              class="video side mt-1"
              width="100%"
              autoplay="autoplay"
              :src="
                talent.profile.introVideo !== 'undefined'
                  ? talent.profile.introVideo
                  : ''
              "
              type="video/*"
              :poster="
                talent.profile.user.profileImg
                  ? talent.profile.user.profileImg
                  : ''
              "
              @ended="onEnd()"
            />
            <v-card v-if="videoPlaying" class="overlay" @click="onPlayAgain()">
              <a class="font-weight-bold">Play profile video again</a>
            </v-card>
          </v-card>
          <v-row class="pb-3 mt-1">
            <v-col md="7">
              <h2 class="font-weight-bold black--text">
                {{ displayName }}
              </h2>
            </v-col>
            <v-col md="5">
              <v-btn
                solo
                color="white"
                class="button text-none float-md-right d-inline  mr-3"
                @click="isFollowing ? unfollowTalent() : followTalent()"
              >
                <img
                  class="mr-2 "
                  src="https://assets.website-files.com/5f8e850767e716756b7bf80f/5f9c15006d8f486340206719_user-plus.svg"
                />
                {{ isFollowing ? 'UNFOLLOW' : 'FOLLOW' }}
              </v-btn>
            </v-col>
            <!-- <v-col
              md="2"
              sm="4"
              xs="4"
            >
              <v-btn
                solo
                color="white"
                class="button text-none d-inline  float-md-right ml-2"
              >
                <img
                  src="@/assets/images/icons/upload.svg"
                  height="25"
                  width="25"
                >
              </v-btn>
            </v-col> -->
          </v-row>
          <v-row v-if="tags.length" no-gutters :class="{ 'mb-5': !address }">
            <v-chip
              v-for="area in tags"
              :key="area.id"
              class="mb-2 mr-2 font-weight-bold"
              color="dark_grey"
            >
              {{ area.name }}
            </v-chip>
          </v-row>
          <v-row v-if="address" align="center" no-gutters class="my-5">
            <img
              class="d-inline"
              src="@/assets/images/icons/location.svg"
              height="25"
              width="25"
            />
            <span class="text-subtitle-2">{{ address }}</span>
          </v-row>

          <v-flex>
            <!-- <p
              class="font-size-14 font-weight-500 mt-n2 black--text"
            >
              <img
                class="mr-1"
                src="@/assets/images/icons/pin.png"
              >357 Glendale Avenue, Northridge, CA
            </p> -->
            <p class="font-size-16 font-weight-600 mt-n1 black--text">
              {{ talent.profile.shortIntro }}
            </p>

            <v-col md="12" sm="12" xs="12" class="mt-0 pt-0">
              <v-tabs class="ml-n4" background-color="transparent">
                <v-tab class="text-justify black--text font-weight-medium">
                  {{ $t('more_information') }}
                </v-tab>
                <v-tab class="text-justify black--text font-weight-medium">
                  {{ $t('photos') }}
                </v-tab>

                <v-tab-item class="mt-3">
                  <!-- <v-divider /> -->
                  <v-main
                    class="font-size-16 font-weight-500 mt-4 black--text font-weight-medium text-justify"
                  >
                    <v-col v-if="talent.profile.longIntro">
                      {{ talent.profile.longIntro }}
                    </v-col>
                    <v-col v-else>
                      <p class="text-h6 text-center">
                        {{ $t('no_information') }}
                      </p>
                    </v-col>
                  </v-main>
                </v-tab-item>

                <v-tab-item class="mt-10">
                  <v-row no-gutters>
                    <v-col v-if="gallery && gallery.length">
                      <silent-box :gallery="gallery">
                        <!-- your additional content -->
                      </silent-box>
                    </v-col>
                    <v-col v-else>
                      <p class="text-h6 text-center">
                        {{ $t('no_photos') }}
                      </p>
                    </v-col>
                  </v-row>
                </v-tab-item>
              </v-tabs>
            </v-col>
          </v-flex>
        </v-col>
        <v-overlay :value="loading">
          <v-progress-circular indeterminate color="primary" size="64" />
        </v-overlay>
        <!-- Right Section -->
        <BookingSteps :talent="talent" :name="displayName" :host="hostId" />
        <v-col md="1" sm="12" xs="12" />
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import VueSilentbox from 'vue-silentbox'
import {
  FOLLOW_TALENT,
  UNFOLLOW_TALENT,
  GET_FOLLOW_TALENT_LIST,
} from '@/graphql/member'
import { PROFILE } from '@/graphql/talent'
import BookingSteps from '@/components/booking/BookingSteps.vue'

Vue.use(VueSilentbox)

export default {
  components: {
    BookingSteps,
  },
  data() {
    return {
      talent: {
        profile: {
          user: {},
          areas: [],
        },
        gallery: [],
        sessions: [],
      },
      isFollowing: false,
      image: [],
      loading: false,
      videoPlaying: false,
    }
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'user', 'jwt']),
    displayName() {
      if (this.talent.profile.user.firstName)
        return `${this.talent.profile.user.firstName} ${this.talent.profile.user.lastName}`
      else return ''
    },
    hostId() {
      return this.talent.profile.user.id
    },
    gallery() {
      return this.talent.gallery.map(image => {
        return {
          src: image.image,
          description: '',
          thumbnailWidth: '200px',
          thumbnailHeight: '190px',
        }
      })
    },
    address() {
      return this.talent.profile.user.country && this.talent.profile.user.city
        ? `${this.talent.profile.user.city}, ${this.talent.profile.user.country.name}`
        : null
    },
    tags() {
      return this.talent.profile.areas.filter(x => x.level !== 1)
    },
  },
  watch: {
    '$route.params.slug'(slug) {
      this.getProfile()
    },
  },
  created() {
    this.userInfo = this.$store.getters['user']
    this.userInfo.accesstoken = this.$store.getters['jwt']
  },
  mounted() {
    this.getProfile()
  },
  methods: {
    onEnd() {
      this.$refs.video_profile.removeAttribute('autoplay')
      this.$refs.video_profile.load()
      this.videoPlaying = true
    },
    onPlayAgain() {
      this.videoPlaying = false
      this.$refs.video_profile.play()
    },
    /**
     * Get Talent Profile
     */
    async getProfile() {
      this.loading = true
      const {
        data: { getBookingPageInfo },
      } = await this.$apollo.query({
        query: PROFILE,
        variables: {
          username: this.$router.currentRoute.params.slug,
        },
      })
      console.log(getBookingPageInfo)
      if (!getBookingPageInfo.success) {
        this.loading = false
        this.$router.replace({ name: 'PageNotFound' })
      } else {
        this.talent = { ...getBookingPageInfo }
        console.log(this.talent)
        if (this.isLoggedIn) {
          await this.getFollowTalentLists()
        }
        this.loading = false
      }
    },

    /**
     * Follow Talent
     */
    async followTalent() {
      if (!this.isLoggedIn) {
        this.$route.query.redirect = `/talent/profile/${this.talent.profile.user.username}`
        this.$store.commit('loginModal', true)
        return
      }
      try {
        const {
          data: { followTalent },
        } = await this.$apollo.mutate({
          mutation: FOLLOW_TALENT,
          variables: {
            followInput: {
              userEmail: this.user.email,
              accesstoken: this.jwt,
              talentEmail: this.talent.profile.user.email,
            },
          },
        })

        if (followTalent.success) {
          this.isFollowing = true
        }
      } catch (err) {
        console.warn('Error following talent', err)
      }
    },

    /**
     * Unfollow Talent
     */
    async unfollowTalent() {
      if (!this.isLoggedIn) {
        this.$route.query.redirect = `/talent/profile/${this.talent.profile.user.username}`
        this.$store.commit('loginModal', true)
        return
      }
      try {
        const {
          data: { unfollowTalent },
        } = await this.$apollo.mutate({
          mutation: UNFOLLOW_TALENT,
          variables: {
            followInput: {
              userEmail: this.user.email,
              accesstoken: this.jwt,
              talentEmail: this.talent.profile.user.email,
            },
          },
        })
        if (unfollowTalent.success) {
          this.isFollowing = false
        }
      } catch (err) {
        console.warn('Error unfollowing talent', err)
      }
    },

    /**
     * Back action
     */
    back() {
      this.$router.back()
    },

    /**
     * Get Follow Talent Lists
     */
    async getFollowTalentLists() {
      const {
        data: { getFollowList },
      } = await this.$apollo.query({
        query: GET_FOLLOW_TALENT_LIST,
        variables: {
          followInput: {
            pagination: {
              skip: 0,
              first: 0,
            },
            email: this.user.email,
            accesstoken: this.jwt,
          },
        },
      })
      if (getFollowList.success) {
        this.isFollowing = getFollowList.userList.some(
          user => user.id === this.talent.profile.user.id
        )
      }
    },
  },
}
</script>

<style lang="scss">
.profile-main-image {
  background-position: top right !important;
  background-size: auto !important;
}
.stepper-header {
  background-color: #f1f3f6;
}

.v-stepper,
.v-stepper__header {
  box-shadow: none;
}

.v-stepper__step__step {
  font-size: 0.9rem;
  height: 34px;
  min-width: 24px;
  width: 34px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.sticky-bar {
  position: sticky;
  top: 100px;
}

.button {
  box-shadow: 0px 20px 30px gainsboro;
}

.theme--light.v-tabs-items {
  background-color: unset;
}
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none;
}

.silentbox-item {
  margin: 0 5px 5px 5px;
}

.video_container {
  position: relative;
}
.video_container video {
  position: relative;
  z-index: 0;
}
.overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  opacity: 0.55;
  transform: translate(-50%, -50%);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black !important;
}
</style>
