<template>
  <v-col md="5" sm="12" xs="12" background-color="transparent">
    <v-stepper v-model="e1" class="sticky-bar" background-color="transparent">
      <v-stepper-header class="stepper-header pa-0">
        <template v-for="n in steps">
          <v-stepper-step
            :key="`${n}-step`"
            :complete="e1 > n"
            :step="n"
            flat
            tile
            active-class="secondary"
          />

          <v-divider v-if="n !== steps" :key="n" />
        </template>
      </v-stepper-header>

      <v-card color="transparent" rounded>
        <v-form ref="step1">
          <v-stepper-content step="1" background-color="grey">
            <v-card color="white" flat>
              <AlertBox :message="error" class="mb-2" />
              <h2 class="mb-2 font-weight-bold">
                {{ $t('book_session') }} {{ name }}
              </h2>
              <h5 class="mb-2 text--secondary">
                {{ $t('session_types') }}
              </h5>

              <v-select
                v-model="bookingData.sessionid"
                :items="sessions"
                placeholder="Select Session Type"
                outlined
                :rules="rules.required"
                @change="getAppointments()"
              />
              <h5 class="ml-1 text--secondary">
                {{ $t('available_dates') }}
              </h5>
              <v-row no-gutters>
                <v-col cols="12" md="7" class="ml-1">
                  <v-menu v-model="menu1" :close-on-content-click="false">
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        :value="computedDateFormattedMomentjs"
                        clearable
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        @click:clear="bookingData.date = null"
                      />
                    </template>
                    <v-date-picker
                      v-model="bookingData.date"
                      :rules="rules.required"
                      :min="currentDate"
                      @change=";(menu1 = false), getAppointments()"
                    />
                  </v-menu>
                </v-col>
              </v-row>
              <v-card flat tile>
                <h5 class="ml-2 mt-3 text--secondary mb-3">
                  {{ $t('select_time') }}
                </h5>

                <TimeBlock
                  v-if="!appointmentLoader && appointments.length"
                  :appointments="appointments"
                  :start-time.sync="bookingData.startTime"
                  :end-time.sync="bookingData.endTime"
                />
                <v-card-text
                  v-else-if="appointments.length === 0 && !appointmentLoader"
                  class="text-center"
                >
                  {{ $t('no_timeslot_found') }}
                </v-card-text>
                <v-card-text v-else class="text-center">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                    class="text-center"
                  />
                </v-card-text>
                <br />
                <v-btn
                  :disabled="sameHost"
                  data-cy="sch_session"
                  block
                  color="secondary"
                  :loading="loading"
                  class="white--text mt-10 no-shadow"
                  @click="!sameHost ? next() : ''"
                >
                  {{ $t('book_your_time') }} {{ name }}!
                </v-btn>
              </v-card>
            </v-card>
            <span v-if="sameHost" class="text-center d-block mt-3 red--text">
              {{ $t('cannot_book_own_session') }}
            </span>
          </v-stepper-content>
        </v-form>
      </v-card>
      <v-card color="transparent" rounded="lg">
        <v-stepper-content step="2" class>
          <v-card v-if="e1 == 2" color="white" flat tile>
            <v-btn
              style="margin-top:-50px; box-shadow:none;"
              color="white"
              class="text-none float-left button"
              :ripple="false"
              @click="back()"
            >
              <img src="@/assets/images/icons/left.png" />
              {{ $t('back') }}
            </v-btn>
            <AlertBox :timeout="60000" :message="error" class="mb-2" />
            <h2 class="mt-10">{{ $t('finalize_booking') }}</h2>
            <h5 class="font-size-16 font-weight-bold mt-5">
              {{ selectedSession.length }}-mins Consultation
              <span class="yellow--text ml-3">
                ${{ getPriceWithRound(selectedSession.charge) }}
              </span>
            </h5>
            <h5 class="font-size-16 font-weight-normal mt-5">
              You're all set to talk with {{ talent.profile.user.firstName }}
              {{ talent.profile.user.lastName }} on
              {{ bookingData.date | formatDate('dddd MMMM DD, YYYY') }}, at
              {{
                `${bookingData.date} ${bookingData.startTime}`
                  | formatDate('hh:mm a')
              }}
              {{ `(${$moment.tz($defaultTZ).zoneAbbr()})` }}.
            </h5>
            <p class="font-size-16 font-weight-normal mt-5">
              {{ talent.profile.shortIntro }}
            </p>
            <h5 class="font-size-15 font-weight-600 mt-3">
              {{ $t('payment_details') }}
            </h5>

            <v-row no-gutters>
              <v-flex xs12 mt-3>
                <stripe-element-card
                  ref="elementRef"
                  :disabled="loading"
                  :hide-postal-code="true"
                  :pk="pulishableKey"
                  @token="payAndBookSession"
                />
              </v-flex>
            </v-row>
            <v-card-text>
              <v-btn
                data-cy="sch_session"
                block
                color="secondary"
                type="submit"
                :loading="loading"
                class="white--text no-shadow"
                @click="$refs.elementRef.submit()"
              >
                {{ $t('schedule_session') }}
              </v-btn>
            </v-card-text>
            <v-flex md="12">
              <v-dialog v-model="dialog">
                <template #activator="{ on, attrs }">
                  <v-btn v-bind="attrs" text medium block v-on="on">
                    {{ $t('faq') }}
                  </v-btn>
                </template>

                <v-card>
                  <v-btn
                    icon
                    light
                    absolute
                    right
                    class="mr-1 mt-1 cross-faq"
                    color="black"
                    @click="close()"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>

                  <v-card-title class="text-center">
                    {{ $t('faq') }}
                  </v-card-title>
                  <Faq />
                </v-card>
              </v-dialog>
            </v-flex>
          </v-card>
        </v-stepper-content>
      </v-card>
      <v-card color="transparent" rounded="lg">
        <v-stepper-content step="3">
          <v-card v-if="e1 == 3">
            <v-card-text class="text-center black--text">
              <img class="image" src="@/assets/images/icons/pay_success.png" />
              <p class="pt-4 text-h5">
                {{ $t('payment_successful') }}
              </p>
              <p class="mt-5 text-h6 font-weight-light text-left">
                {{ $t('successfully_book') }} {{ name }}!
              </p>
            </v-card-text>
            <v-card outlined class="pb-3">
              <v-row md="12">
                <v-col md="5" class="mt-1">
                  <h5
                    class="font-size-14 font-weight-500 font-weight-light ml-5"
                  >
                    {{ $t('session') }}
                  </h5>
                  <h5 class="ml-5">
                    {{ selectedSession.length }}-mins Consultation
                  </h5>

                  <h5
                    class="
                      font-size-14 font-weight-500
                      mt-4
                      font-weight-light
                      ml-5
                    "
                  >
                    {{ $t('duration') }}
                  </h5>
                  <h5 class="ml-5">
                    {{ selectedSession.length }} mins/${{
                      getPriceWithRound(selectedSession.charge)
                    }}
                  </h5>
                </v-col>
                <v-divider vertical class="mt-3 ml-8" />
                <v-col md="5" class="mt-1 ml-4">
                  <h5 class="font-size-14 font-weight-500 font-weight-light">
                    {{ $t('date') }}
                  </h5>
                  <h5 class>
                    {{ bookingData.date | formatDate('dddd MMMM DD, YYYY') }}
                  </h5>

                  <h5
                    class="font-size-14 font-weight-500 mt-4 font-weight-light"
                  >
                    {{ $t('time') }}
                  </h5>
                  <h5 class>
                    {{
                      `${bookingData.date} ${bookingData.startTime}`
                        | formatDate('hh:mm a')
                    }}.
                  </h5>
                  <br />
                </v-col>
              </v-row>
            </v-card>

            <v-flex v-if="!isTalent" md="12" mt-7>
              <v-btn
                width="100%"
                data-cy="sch_session"
                color="secondary"
                dark
                to="/upcoming-sessions"
                class="no-shadow"
              >
                {{ $t('view_appointments') }}
              </v-btn>
            </v-flex>
            <v-flex v-if="isTalent" md="12" mt-7>
              <v-btn
                width="100%"
                data-cy="sch_session"
                color="secondary"
                dark
                to="/talent/appointments"
                class="no-shadow"
              >
                {{ $t('view_appointments') }}
              </v-btn>
            </v-flex>

            <v-flex md="12" class="mt-2">
              <v-dialog v-model="dialog">
                <template #activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    class="mt-1 pa-2"
                    width="100%"
                    text
                    v-on="on"
                  >
                    {{ $t('faq') }}
                  </v-btn>
                </template>

                <v-card>
                  <v-btn
                    icon
                    light
                    absolute
                    right
                    class="mr-n4 mt-4 cross-faq"
                    color="black"
                    @click="close()"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>

                  <v-card-title class="ml-5">
                    {{ $t('faq') }}
                  </v-card-title>

                  <Faq />
                </v-card>
              </v-dialog>
            </v-flex>
          </v-card>
        </v-stepper-content>
      </v-card>
    </v-stepper>
  </v-col>
</template>
<script>
import { mapGetters } from 'vuex'
import { format, parseISO } from 'date-fns'
import { isEmpty, head, map } from 'lodash'
import { StripeElementCard } from '@vue-stripe/vue-stripe'
import { STRIPE_PUBLISHABLE_KEY, TEST_STRIPE_PUBLISHABLE_KEY } from '../../consts'

// graphql
import { GET_TALENT_AVAILABILITY } from '@/graphql/talent'
import { PAY_AND_BOOK_SESSION } from '@/graphql/booking'

// component
import TimeBlock from '@/components/booking/TimeBlock.vue'
import Faq from '@/components/booking/Faq.vue'

// utils
import { rules } from '@/utils/validation'
import i18n from '@/utils/i18n.js'
import { getPriceWithRound } from '../../utils/helpers'
import { TestEmails } from '../../utils/string'

export default {
  components: {
    Faq,
    TimeBlock,
    StripeElementCard,
  },
  props: {
    disabled: { type: Boolean, default: () => false },
    member: { type: Object, default: () => {} },
    name: { type: String, default: () => '' },
    talent: { type: Object, default: () => {} },
    host: { type: String, default: () => {} },
  },

  data() {
    return {
      pulishableKey: "",
      bookingData: {
        sessionid: null,
        date: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
        startTime: null,
        endTime: null,
      },
      currentDate: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
      sessions: [],
      appointments: [],
      selectedSession: {},
      rules,
      e1: 1,
      menu1: false,
      steps: 3,
      dialog: false,
      cvv: 123,
      cardnumber: '4242 4242 4242 4242',
      expiry: '01/25',
      error: null,
      loading: false,
      appointmentLoader: false,
      success: null,
    }
  },
  computed: {
    ...mapGetters(['isTalent', 'user', 'jwt']),
    computedDateFormattedMomentjs() {
      return this.bookingData.date
        ? this.$moment(this.bookingData.date).format('dddd, MMMM Do YYYY')
        : ''
    },
    loggedIn() {
      return !!this.$store.state.jwt
    },
    sameHost() {
      return this.host === this.user.id
    },
  },
  mounted() {
    this.pulishableKey = TestEmails.includes(this.user.email)?TEST_STRIPE_PUBLISHABLE_KEY:STRIPE_PUBLISHABLE_KEY
  },
  watch: {
    talent(talent) {
      this.bookingData.sessionid = isEmpty(talent.sessions)
        ? null
        : head(talent.sessions).id
      this.selectedSession = isEmpty(talent.sessions)
        ? {}
        : head(talent.sessions)
      map(this.talent.sessions, session => {
        this.sessions.push({
          text:
            session.title +
            ' - ' +
            session.length +
            ' mins. ' +
            '/ $' +
            getPriceWithRound(session.charge),
          value: session.id,
        })
      })
      this.getAppointments()
    },
  },
  methods: {
    /**
     * Close FAQ Dialog
     */
    close() {
      this.dialog = false
    },

    /**
     * Redefine getPriceWithRound function
     */
    getPriceWithRound: getPriceWithRound,

    /**
     * Validate 1st step
     * If user not loggedIn. Open loginModal
     */
    next() {
      if (!this.loggedIn) {
        this.$route.query.redirect = `/talent/profile/${this.talent.profile.user.username}`
        this.$store.commit('loginModal', true)
      } else if (this.$refs.step1.validate()) {
        console.log(this.isTalent)
        if (this.bookingData.date == null) {
          this.error = i18n.t('select_date')
        } else if (this.bookingData.startTime == null) {
          this.error = i18n.t('select_session_tm')
        } else {
          this.error = null
          this.nextStep(1)
        }
      }
    },
    back() {
      this.nextStep(3)
    },

    /**
     * Set the next step
     */
    nextStep(n) {
      if (n === this.steps) {
        this.e1 = 1
      } else {
        this.e1 = n + 1
      }
    },

    /**
     * Pay and Book New Session
     */
    async payAndBookSession(token) {
      try {
        this.error = null
        this.loading = true
        if (!isEmpty(token)) {
          const {
            data: { payAndBookSession },
          } = await this.$apollo.mutate({
            mutation: PAY_AND_BOOK_SESSION,
            variables: {
              bookingData: {
                email: this.user.email,
                accesstoken: this.jwt,
                cardToken: token.id,
                ...this.bookingData,
                tzinfo: this.$defaultTZ,
              },
            },
          })
          if (payAndBookSession.success) {
            this.selectedSession = payAndBookSession.bookedSession.sessionType
            this.loading = false
            this.nextStep(2)
          } else {
            this.error = this.$t(payAndBookSession.error)
            this.loading = false
          }
        }
      } catch (e) {
        this.loading = false
        console.log(e) // eslint-disable-line
      }
    },

    /**
     * Get Talent Appointments Based On Session and Date
     */
    async getAppointments() {
      if (this.bookingData.sessionid && this.bookingData.date) {
        this.appointmentLoader = true
        try {
          this.selectedSession = this.talent.sessions.find(
            session => session.id === this.bookingData.sessionid
          )
          const {
            data: { fetchAppointments },
          } = await this.$apollo.query({
            query: GET_TALENT_AVAILABILITY,
            fetchPolicy: 'no-cache',
            variables: {
              appointment: {
                date: this.bookingData.date,
                sessionid: this.bookingData.sessionid,
                tzinfo: this.$defaultTZ,
              },
            },
          })
          if (fetchAppointments.success) {
            this.appointmentLoader = false
            this.appointments = fetchAppointments.appointmentList
          } else {
            this.appointmentLoader = false
            this.appointments = []
          }
        } catch (error) {
          this.appointmentLoader = false
          console.log(error)
        }
      }
    },
  },
}
</script>
